












































































































import Vue from "vue";
import { mapGetters } from "vuex";
import { TranslateResult } from "vue-i18n";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "UploadBulkResume",
  data() {
    return {
      resume: null as File[] | null,
      drop_file_image: require("@/assets/icons/linear/document-upload.svg"),
      error_msg: "" as TranslateResult,
      show_error_alert: false as boolean,
      value: undefined as string | undefined
    };
  },
  props: {
    number_of_files: {
      type: Number,
      default: 15
    },
    file_size: {
      type: Number,
      default: 1048576
    },
    title: {
      type: String
    },
    file_size_number: {
      type: Number,
      default: 1
    },
    btn_text: {
      type: String,
      default: "Next"
    },
    no_proceed_btn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  methods: {
    /**
     * Validate user selected resume through drag & drop
     * @param value{DragEvent}
     */
    process_dropped_resume(value: DragEvent) {
      if (value && value.dataTransfer) {
        // Convert FileList to array of File
        if (this.resume && this.resume.length > 0)
          this.resume = [
            ...this.resume,
            ...Array.from(value.dataTransfer.files)
          ];
        else this.resume = Array.from(value.dataTransfer.files);
        this.validate_resume_type_and_size();
        if (this.no_proceed_btn) this.submit_form();
      }
    },
    /**
     * Validate user selected resume through file uploader
     * @param file{File}
     */
    process_selected_resume(files: File[]) {
      if (this.resume && this.resume.length) {
        this.resume = [...this.resume, ...files];
      } else this.resume = files;
      this.validate_resume_type_and_size();
      if (this.no_proceed_btn) this.submit_form();
    },
    /**
     * Validate selected resume type: .png, .jpg & .svg are valid types
     * @param file{File}  => selected file
     * @return boolean
     */
    validate_resume_type_and_size() {
      let length = this.resume?.length || 0;
      if (this.resume && this.resume.length) {
        if (this.resume.length > this.number_of_files) {
          this.show_error_alert = true;
          this.error_msg = this.$t("shared.files-size", {
            count: this.number_of_files
          }).toString();
          this.resume = this.resume.splice(0, this.number_of_files);
          length = this.resume.length;
        }
        this.resume = this.resume.filter((file: File) => {
          return (
            (file.size <= this.file_size && file.type === "text/plain") ||
            file.type === "application/pdf" ||
            file.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/doc" ||
            file.type === "application/ms-doc" ||
            file.type === "application/msword"
          );
        });
      }
      if (length !== this.resume?.length) {
        this.show_error_alert = true;
        this.error_msg = this.$t("shared.file-type-and-size").toString();
      }
    },
    /**
     * Convert file size from bytes to MB
     * @param size{number}  file size
     * @return string file size in MB
     */
    get_file_size(size: number): string {
      return (size / (1024 * 1024)).toFixed(2);
    },
    /**
     * Reset variables
     */
    reset_resume(index: number) {
      this.resume = this.resume?.filter((item, ind) => ind !== index) || [];
      this.error_msg = "";
      this.show_error_alert = false;
      const file_input = this.$refs.fileInput as any;
      if (file_input) {
        this.value = "";
        file_input.reset();
      }
    },
    /**
     * Submit form => pass data to parent with step and resume file
     */
    submit_form() {
      const files = this.resume as File[];
      this.$emit("uploaded_files", {
        value: files
      });
    },
    file_input() {
      const file_uploader = this.$refs.fileInput as any;
      file_uploader.$refs.input.click();
    }
  }
});
