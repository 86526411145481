
















































import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "DeleteItem",
  props: {
    title: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    sub_title: {
      type: String as PropType<TranslateResult>
    },
    submit_btn_text: {
      type: String as PropType<TranslateResult>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    transform_del_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children) node.setAttribute("fill", "#E32D6B");
      return icon;
    }
  }
});
