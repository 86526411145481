
























































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { UPLOAD_FILE_CHUNK } from "@/store/modules/common/constants";
import {
  DELETE_KNOWLEDGE_BASE,
  GET_KNOWLEDGE_BASE,
  UPLOAD_KNOWLEDGE_BASE_DOCUMENT
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import ProgressUploader from "@/components/shared/ProgressUploader.vue";
import {
  KnowledgeBasePayload,
  KnowledgeData
} from "@/store/modules/recruiter/interfaces";
import DeleteItem from "@/components/shared/DeleteItem.vue";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { format_date } from "@/utils/global";
import UploadBulkResume from "@/components/shared/UploadBulkResume..vue";

export default Vue.extend({
  name: "DigitalHumanKnowledgeBase",
  components: {
    ProgressUploader,
    DeleteItem,
    UploadBulkResume
  },
  data() {
    return {
      selected_file: {} as KnowledgeData, // Selected file
      media_uploading: false, // To check if media uploading is in progress or not
      media_uploading_title: "", // Media uploading title
      media_uploading_progress: 0, // Media uploading progress
      knowledge_base_payload: [] as KnowledgeBasePayload[], // Knowledge base payload
      show_delete_dialog: false, // To show delete dialog
      delete_btn_loading: false, // To show delete button loading
      loading: false, // To show pagination loading
      upload_btn_loading: false, // To show upload button loading
      status_color: "warning", // Status color
      status_icon: "mdi-alert-circle-outline", // Status icon
      selected_upload_file: null as File | null // Selected file
    };
  },
  computed: {
    ...mapGetters("recruiter", {
      get_knowledge_base: GET_KNOWLEDGE_BASE
    }),
    getTitle(): string {
      return this.$t("shared.data-not-found").toString();
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  mounted() {
    this.init_knowledge_base(this.get_knowledge_base.page);
  },
  methods: {
    format_date,
    ...mapActions("recruiter", {
      upload_file: UPLOAD_KNOWLEDGE_BASE_DOCUMENT,
      get_document: GET_KNOWLEDGE_BASE,
      delete_document: DELETE_KNOWLEDGE_BASE
    }),
    ...mapActions("common", {
      upload_file_chunk: UPLOAD_FILE_CHUNK
    }),
    ...mapMutations({
      set_root_error: ROOT_ERROR,
      set_root_notification: ROOT_NOTIFICATION
    }),
    async init_knowledge_base(page = 1) {
      this.loading = true;
      this.get_knowledge_base.page = page;
      const response = await this.get_document(this.get_knowledge_base);
      if (response) {
        this.get_knowledge_base.data = response.files as KnowledgeBasePayload[];
        this.get_knowledge_base.info = response.info;
        this.get_knowledge_base.total = response.total;
      }
      this.loading = false;
    },
    async delete_knowledge_base() {
      this.delete_btn_loading = true;
      const response = await this.delete_document(this.selected_file.uri);
      if (response) {
        this.set_root_notification(
          `${this.$t("recruiter.knowledge-base.document-delete")}`
        );
        this.init_knowledge_base(this.get_knowledge_base.page);
      } else {
        this.set_root_error(
          `${this.$t("recruiter.knowledge-base.document-delete-failed")}`
        );
      }
      this.delete_btn_loading = false;
      this.show_delete_dialog = false;
    },
    async upload_knowledge_base_file() {
      if (this.selected_upload_file) {
        this.upload_btn_loading = true;
        let file_chunks_uploaded = false;
        let file = this.selected_upload_file;
        let filename = "" as string;
        const date = Date.now();
        filename = `${date}_$_${file.name}`;
        const chunkSize = 3 * 1024 * 1024; // 3MB chunk size
        this.media_uploading_title = this.$t(
          "recruiter.knowledge-base.document-upload-title"
        ).toString();
        this.media_uploading = true; // Set media uploading to true
        let start = 0;
        let retry = 0;

        while (start < file.size) {
          let end = Math.min(start + chunkSize, file.size);
          let chunk = file.slice(start, end);

          const formData = new FormData(); // Create a form data
          formData.append("file", chunk, filename); // Append chunk to form data
          formData.append("filename", filename);

          const response = await this.upload_file_chunk(formData); // Api call to upload file chunk
          if (response && retry < 15) {
            start = end; // If response exist then set start to end
            retry = 0;
            this.media_uploading_progress = Math.round(
              (start / file.size) * 100
            ); // Set media uploading progress
            file_chunks_uploaded = true;
          } else if (retry >= 15) {
            this.set_root_error(
              `${this.$t("recruiter.knowledge-base.document-upload-failed")}`
            );
            file_chunks_uploaded = false;
            break;
          } else {
            retry += 1;
          }
        }
        if (file_chunks_uploaded) {
          // If Document uploaded successfully
          const response = await this.upload_file(filename); // Api call to upload file
          if (response) {
            await this.init_knowledge_base(this.get_knowledge_base.page);
            this.reset_resume(0);
            this.set_root_notification(
              `${this.$t("recruiter.knowledge-base.document-upload")}`
            );
          } else {
            this.set_root_error(
              `${this.$t("recruiter.knowledge-base.document-upload-failed")}`
            );
          }
        }
        this.media_uploading = false; // Set media uploading to false
        this.media_uploading_title = ""; // Set media uploading title to empty
        this.media_uploading_progress = 0; // Set media uploading progress to 0

        this.upload_btn_loading = false; // Upload button loading to false
      }
    },
    extract_filename(filename: string): string {
      const parts = filename.split("_$_");
      if (parts.length > 1) {
        return parts.slice(1).join("_");
      }
      return filename;
    },
    get_status(): string {
      const info = this.get_knowledge_base.info;
      // Function to get file status
      if (info.flag && info.msg) {
        this.status_icon = "mdi-check";
        this.status_color = "primary";
        return this.$t("recruiter.knowledge-base.processed").toString();
      } else if (!info.flag && info.msg) {
        this.status_color = "error";
        this.status_icon = "mdi-delete-forever";
        return this.$t("recruiter.knowledge-base.failed").toString();
      } else {
        this.status_color = "success";
        this.status_icon = "mdi-alert-circle-outline";
        return this.$t("recruiter.knowledge-base.processing").toString();
      }
    },
    uploaded_file(files: any) {
      if (files?.value?.length) this.selected_upload_file = files.value[0];
    },
    reset_resume(index: number) {
      const upload_bulk = this.$refs.upload_bulk as InstanceType<
        typeof UploadBulkResume
      >;
      if (upload_bulk) {
        upload_bulk.reset_resume(index);
      }
    }
  }
});
